//#region IMPORT

import { Router } from "@angular/router";
import { PartialObserver } from "rxjs";
import { RegionalOfficeModel } from "src/app/models/regionalofficemodel";
import { UserModel } from "src/app/models/user.model";
import { SessionService } from "src/app/services/session.service";
import { BasePublicComponent } from "./basepublic.component";

//#endregion


//#region CLASS

export class BaseAuthourizeComponent extends BasePublicComponent
{
	//#region DECLARATION

	public _modelUserSignIn: UserModel;
	public _modelRegionalOfficeSignIn: RegionalOfficeModel;
	public _modelNull: any = null;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._modelUserSignIn = new UserModel();
		this._modelRegionalOfficeSignIn = new RegionalOfficeModel();

		const observerPartialModelUserSignIn: PartialObserver<UserModel> =
		{
			next: (modelUser: UserModel) => { this._modelUserSignIn = modelUser; },
			error: () => { },
			complete: () => { }
		};

		const observerPartialModelRegionalOfficeSignIn: PartialObserver<RegionalOfficeModel> =
		{
			next: (modelRegionalOfficeModel: RegionalOfficeModel) => { this._modelRegionalOfficeSignIn = modelRegionalOfficeModel; },
			error: () => { },
			complete: () => { }
		};

		this._serviceSession._modelUserSignIn.subscribe(observerPartialModelUserSignIn);
		this._serviceSession._modelRegionalOfficeSignIn.subscribe(observerPartialModelRegionalOfficeSignIn);
	}

	//#endregion


	//#region FUNCTION

	protected signOut(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin"]);
	}

	protected signOutDownload(stringURL: string): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin", stringURL]);
	}

	//#endregion


	//#region CHECK

	protected checkURL(stringURLPath: string): boolean
	{
		return this._router.url.indexOf(stringURLPath) > -1;
	}

	//#endregion
}

//#endregion