//#region IMPORT

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { HandshakeServiceInterface } from "../interfaces/handshake.service.interface";
import { HandshakeModel } from "../models/handshake.model";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GuardServiceInterface } from "../interfaces/guard.service.interface";
import { SessionService } from "./session.service";
import { BaseService } from "./bases/base.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class HandshakeService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region PUBLIC

	generateToken(interfaceHandshakeService: HandshakeServiceInterface, modelHandshake: HandshakeModel): Observable<boolean>
	{
		return this.callServiceHandshake(interfaceHandshakeService, JSON.stringify(modelHandshake), WebAddressConstant.STRING_URL_HANDSHAKE_GENERATETOKEN);
	}

	refreshToken(interfaceService: HandshakeServiceInterface, modelHandshake: HandshakeModel): Observable<boolean>
	{
		return this.callServiceHandshakeRefresh(interfaceService, JSON.stringify(modelHandshake), WebAddressConstant.STRING_URL_HANDSHAKE_REFRESHTOKEN);
	}

	//#endregion


	//#region PRIVATE

	refreshTokenForRegionalOffice(interfacGuardService: GuardServiceInterface, modelHandshake: HandshakeModel): Observable<boolean>
	{
		return this.callServicePrivateForGuard(interfacGuardService, JSON.stringify(modelHandshake), WebAddressConstant.STRING_URL_HANDSHAKE_REFRESHTOKENFORREGIONALOFFICE);
	}

	//#endregion
}

//#endregion